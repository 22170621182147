.pcwrapper{
    width: 100vw;
}

.home-page-container {
    display: flex;
    flex-direction: column;
}

.section-one-container {
    position: relative;
    background: #000000;
    width: 1920px;
    height: 1120px;
    max-height: 100vh;
    background-size: 1920px min(1120px, 100vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
.arrow-pointer {
    position: absolute;
    bottom: 10px;
    width: 50px;
    height: 50px;
    left: 924px;
    cursor: pointer;
}

.arrow-img {
    width: 32px;
    height: 19px;
}
  
@keyframes bounce{
    0%,
    5% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
}

.bounce{
    animation: bounce 1.5s infinite;
}
  
.left-slogan {
    margin-left: 160px;
    z-index: 1;
}
  
.left-slogan-text {
    width: 1024px;
    color: #FFFFFF;
    font-family: DIN-Bold;
    font-size: 58px;
    line-height: 72px;
}
  
.play-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 54px;
    border: 3px solid #FFFFFF;
    margin-top: 58px;
    cursor: pointer;
}

.play-icon:hover {
    background: #FFFFFF;
    color: #000000;
}

.play-icon:hover .watchText {
    background: #FFFFFF;
    color: #000000;
}

.play-icon:hover .watchIcon {
    background-image: url("@/static/img/playBlack.png");
}

.watchIcon {
    width: 10px;
    height: 16px;
    background-image: url("@/static/img/playwhite.png");
    background-size: cover;
}

.watchText {
    margin-left: 20px;
    width: 51px;
    height: 22px;
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 22px;
}
  
.main-page-section1-video-container {
    position: absolute;
    right: 0;
    width: 1214px;
    height: 802px;
}
  
.main-page-section1-video {
    width: 100%;
    height: 100%;
}

.section-two-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 1920px;
    height: 700px;
    background: #000000;
    position: relative;
}

.section-two-text-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    z-index: 2;
}

.annual-growth-rate {
    width: 385px;
    height: 130px;
    color: #FFFFFF;
}

.user {
    width: 385px;
    height: 130px;
    color: #FFFFFF;
}

.countries {
    width: 385px;
    height: 130px;
    color: #FFFFFF;
}

.fontSize72 {
    font-size: 72px;
    font-weight: 600;
    line-height: 90px;
    text-align: center;
}

.fontSize26 {
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
}

.home-section2-video-cover {
    position: absolute;
    top: 0px;
    width: 1920px;
    height: 700px;
    z-index: 1;
}

.official {
    width: 1200px;
    height: 900px
}

.home-section2-video-container {
    position: absolute;
    top: 0px;
    width: 1920px;
    height: 700px
}

.home-section2-video {
    width: 1920px;
    height: 700px
}

.section-three-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #000000;
    padding-top: 170px;
}

.animation-fanIn {
    animation: fanIn 1s forwards
}

@keyframes fanIn {
    to {
        height: 0
    }
}

.section-three-layer-container {
    position: relative;
    width: 1920px;
    height: 906px;
}

.section-three-layer0 {
    position: absolute;
    width: 1920px;
    height: 906px;
    background: black;
    z-index: 1;
}


.section-three-layer1 {
    position: absolute;
    width: 1920px;
    height: 906px;
}

.section-three-layer2 {
    position: absolute;
    width: 1920px;
    height: 906px;
    opacity: 0;
}

.animation-showup0 {
  animation: showup 1s forwards;
}

.animation-showup {
    animation: showup 1s forwards 1s;
}

.animation-showup1 {
    animation: showup 1s forwards 0s;
}
.animation-showup2 {
    animation: showup 1s forwards 0.3s;
}
.animation-showup3 {
    animation: showup 1s forwards 0.3s;
}
.animation-showup4 {
    animation: showup 1s forwards 0.5s;
}
.animation-showup5 {
    animation: showup 1s forwards 0.7s;
}
.animation-showup6 {
    animation: showup 1s forwards 1.0s;
}
.animation-showup7 {
    animation: showup 1s forwards 1.3s;
}
.animation-showup8 {
    animation: showup 1s forwards 1.5s;
}


@keyframes showup {
    to {
        opacity: 1;
    }
}

.section-three-layer3 {
    position: absolute;
    width: 1920px;
    height: 906px;
    opacity: 0;
}

.ring {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 42px;
  height: 42px;
  z-index: 1;
  opacity: 0;
}

.position1 {
    top: 288px;
    left: 1313px;
}

.position2 {
    top: 270px;
    left: 540px;
}

.position3 {
    top: 400px;
    left: 1013px;
}

.position4 {
    top: 580px;
    left: 1243px;
}

.position5 {
    top: 600px;
    left: 1400px;
}

.position6 {
    top: 525px;
    left: 943px;
}

.position7 {
    top: 400px;
    left: 580px;
}

.position8 {
    top: 580px;
    left: 630px;
}

.circle, .circle1, .circle2, .circle3 {
    width: 15px;
    height: 15px;
    border: 1px solid #D6EEFF;
    border-radius: 999px;
    position: absolute;
}

.circle {
    background-color: #D6EEFF;
}

.circle1, .circle2, .circle3 {
    background: transparent;
    opacity: 0;
    animation: circleChange 4s linear infinite
}

.circle1 {
    animation-delay: 1s;
}

.circle2 {
    animation-delay: 2s;
}

.circle3 {
    animation-delay: 3s;
}

@keyframes circleChange {
    0% { transform: scale(1); opacity: 0.95;}
    25% { transform: scale(2); opacity: 0.75;}
    50% { transform: scale(3); opacity: 0.5;}
    75% { transform: scale(4); opacity: 0.25;}
    100% { transform: scale(5); opacity: 0.05;}
}

.section3Title {
    width: 896px;
    height: 163px;
}

.section-three-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    color: #FFFFFF;
    font-family: "DIN-Bold";
    font-size: 48px;
}

.section-three-subTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 900px;
    color: #FFFFFF;
    font-size: 24px;
    text-align: center;
}

.section-four-container {
    /* box-sizing: border-box; */
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #000000;
    /* padding-top: 170px;
    padding-left: 155px;
    padding-right: 155px; */
}


@keyframes leftIn{
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes rightIn{
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.animation-leftIn {
    animation: leftIn 0.5s forwards;
}

.animation-rightIn {
    animation: rightIn 0.5s forwards;
}


.section4litmatch1 {
    width: 900px;
    height: 580px;
    transform: translateX(-100%);
}

.section4litmatch2 {
    width: 900px;
    height: 580px;
    transform: translateX(100%);
}

.section-four-row-one {
    display: flex;
    position: relative;
    padding-top: 170px;
    padding-left: 155px;
    padding-right: 155px;
}

.section-four-text-fragment-one {
    width: 423px;
    height: 162px;
    font-size: 44px;
    font-weight: bold;
    font-family: 'DIN-bold';
    color: #FFFFFF;
    line-height: 54px;
}

.section-four-text-fragment-two {
    width: 585px;
    height: 204px;
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 34px;
    margin-top: 20px;
    opacity: 0.9;
}


.section-four-row-one-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateX(-100%);
}

.section-four-row-one-text {
    width: 585px;
    height: 386px;
    margin-left: 80px;
    /* background-image: url('@/static/img/home/textbg1.png');
    background-repeat: no-repeat;
    background-size: 585px 386px; */
    animation: rightIn 0.5s linear;
}

.section-four-row-two-text-container {
    box-sizing: border-box;
    padding: 153px 80px 124px 0px;
    width: 600px;
    height: 580px;
    transform: translateX(-100%);
}

.section-four-row-two {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    padding-top: 380px;
    padding-left: 155px;
    padding-right: 155px;
    overflow-x: hidden;
}

.section-four-text-fragment-three {
    height: 108px;
    font-size: 44px;
    font-weight: bold;
    font-family: 'DIN-bold';
    color: #FFFFFF;
    line-height: 54px;
    text-align: right;
}

.section-four-text-fragment-four {
    height: 136px;
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 34px;
    margin-top: 20px;
    text-align: right;
    opacity: 0.9;
}

.section4-textbg1 {
    position: absolute;
    right: 200px;
    top: 280px;
    width: 585px;
    height: 386px;
}

.section4-textbg2 {
    position: absolute;
    left: 0;
    top: 120px;
    width: 310px;
    height: 664px;
}

.section-four-footer {
    width: 100%;
    height: 180px;
    background: #000000;
}

.section-five-container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 1120px;
    background-image: url('@/static/img/historybg.png');
    background-repeat: no-repeat;
    background-size: 1920px 1120px;
    padding-top: 180px;
}

.section-five-title {
    height: 71px;
    font-size: 58px;
    font-weight: bold;
    font-family: 'DIN-Bold';
    color: #FFFFFF;
    line-height: 71px;
    opacity: 0;
}

.history-container {
    display: flex;
    /* width: 100%; */
    box-sizing: border-box;
    padding: 120px 300px 0 300px;
}

.historyItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    height: 480px;
    background-image: url('@/static/img/historyItembg.png');
    background-repeat: no-repeat;
    background-size: 400px 480px;
    opacity: 0;
    margin-left: 15px;
    margin-right: 15px;
    transform: scale(0.5) ;
}

@keyframes showUp {
    25% {
        opacity: 0.1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.animation-history-title-showUp {
    animation: animation-history-title 0.6s forwards;
}

@keyframes animation-history-title {
    from {
        opacity: 0;
        transform: translateY(100px)
    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
}

.animation-history-showUp {
    animation: showUp 0.6s forwards
}

.historylogo {
    width: 56px;
    height: 56px;
}

.historyItemPart1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
}

.historyItemPart2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    width: 220px;
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 29px;
    text-align: center;
}

.historyItemPart3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 136px;
    font-size: 30px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 36px;
}

.section-six-container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 970px;
    background-image: url('@/static/img/capability.png');
    background-repeat: no-repeat;
    background-size: 1920px 970px;
    padding-top: 180px;
    margin-top: -1px;
}

.section-six-title {
    width: 398px;
    height: 71px;
    font-size: 58px;
    color: #FFFFFF;
    line-height: 71px;
    text-align: center;
    font-weight: bold;
    font-family: 'DIN-Bold';
    opacity: 0;
}

.capability-container {
    display: flex;
    justify-content: space-evenly;
    box-sizing: border-box;
    width: 100%;
    padding: 200px;
}

.capabilityItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 320px;
    margin: 0 150px;
    opacity: 0;
}

@keyframes animation-capabilityItem-showUp {
    from {
        opacity: 0;
        transform: translateY(100px) rotate(-0.04turn)
    }
    to {
        opacity: 1;
        transform: translateY(0) rotateX(0)
    }
}

.animation-capabilityItem-showUp {
    animation: animation-capabilityItem-showUp 0.6s forwards
}

.capabilitylogo {
    width: 126px;
    height: 126px;
}

.capabilityItemPart2 {
    width: 366px;
    height: 42px;
    margin-top: 30px;
    font-size: 34px;
    font-weight: 600;
    font-family: 'DIN-bold';
    color: #FFFFFF;
    line-height: 42px;
    text-align: center;
}

.capabilityItemPart3 {
    width: 308px;
    height: 56px;
    margin-top: 20px;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 28px;
    text-align: center;
}

.section-seven-container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 970px;
    overflow: hidden;
    position: relative;
    margin-top: -1px;
}

.section-seven-container-bg {
    width: 100%;
    height: 970px;
    z-index: 0;
    position: absolute;
    animation: zoomIn 40s infinite linear;
}

@keyframes zoomIn {
    0% {
      /* opacity: 0; */
      transform: scale(1);
    }
    50% {
        /* opacity: 0; */
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.section-seven-title {
    width: 1100px;
    height: 136px;
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 34px;
    text-align: center;
    opacity: 0;
    transform: translateY(100px)
}

.signature {
    width: 556px;
    height: 34px;
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 34px;
    margin-top: 40px;
    opacity: 0;
    transform: translateY(100px)
}

.founderlineicon {
    width: 2px;
    height: 157px;
    background: #FFFFFF;
    margin-top: 120px;
}

.company-info {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #fff;
    padding: 20px;
    text-align: right;
    user-select: none;
}

.company-info--privacy{
    cursor: pointer
}

.company-info--privacy:hover {
    text-decoration: underline
}