.mobile-careers-section-six-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 92px;
    background: #000000;
  }

  .mobile-constructLabel {
    height: 22px;
    font-size: 16px;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 22px;
  }