.mobile-home-page-container {
    display: flex;
    flex-direction: column;
    /* background-color: #000000; */
    /* width: calc(100vw - 15px); 滚动条宽度 15 */
}

.mobile-section-one-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: #000000;
    height: 1334px; /*滚动条宽度 15*/
    z-index: 1;
}
  
.mobile-slogan {
    z-index: 1;
    width: 600px;
    position: absolute;
    top: 340px;
    text-align: center;
}
  
.mobile-slogan-text {
    color: #FFFFFF;
    font-weight: bold;
    font-family: 'DIN-bold';
    font-size: 58px;
    line-height: 72px;
}

.mobile-arrow-pointer {
    position: absolute;
    bottom: 50px;
    left: 360px;
    width: 32px;
    height: 19px;
    cursor: pointer;
    z-index: 1;
}
  
.mobile-play-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 54px;
    border: 3px solid #FFFFFF;
    margin-top: 58px;
    cursor: pointer;
    position: absolute;
    top: 580px;
    z-index: 1;
}
  
.mobile-watchText {
    margin-left: 10px;
    font-weight: 600;
    color: #FFFFFF;
}

  
.mobile-main-page-section1-video {
    position: relative;
    width: 750px;
    /* height: 600px; */
}

.mobile-main-page-section1-gif {
    position: relative;
    width: 900px;
    height: 580px;
    left: -70px;
}

.mobile-home-page-img {

}

.mobile-main-page-section1-video-container {
    width: 750px;
    /* height: 600px; */
    position: absolute;
    background-color: #000000;
    top: 773px;
}

.mobile-official {
    width: 100%;
}

.mobile-arrow-img {
    width: 32px;
    height: 19px;
}

.mobile-section-two-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 520px;
    background: #000000;
    position: relative;
}

.mobile-home-section2-video-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    height: 520px;
}

.mobile-home-section2-video {
    width: 100%;
    height: 520px;
}

.mobile-home-section2-gif {
    width: 100%;
    height: 320px;
}

.mobile-annual-growth-rate {
    width: 118px;
    height: 130px;
    color: #FFFFFF;
    z-index: 1;
}

.mobile-user {
    width: 257px;
    height: 130px;
    color: #FFFFFF;
    z-index: 1;
}

.mobile-countries {
    width: 105px;
    height: 130px;
    color: #FFFFFF;
    z-index: 1;
}

.mobile-fontSize72 {
    font-size: 48px;
    font-weight: 500;
    line-height: 90px;
    font-family: 'DIN-Bold';
    text-align: center;
}

.mobile-fontSize26 {
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
}

.mobile-section-three-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 1020px;
    color: #FFFFFF;
    background: #040006;
    position: relative;
    
}

.mobile-section3-title {
    position: absolute;
    top: 180px;
    width: calc(100% - 100px);
    height: 118px;
    font-size: 48px;
    font-family: 'DIN-Bold';
    color: #FFFFFF;
    line-height: 59px;
    text-align: center;
}

.mobile-section3-subTitle {
    position: absolute;
    top: 311px;
    width: 600px;
    height: 114px;
    font-size: 26px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 38px;
    text-align: center;
}

/**/

.animation-fanIn {
    animation: fanIn 1s forwards
}
@keyframes fanIn {
    to {
        height: 0
    }
}
.mobile-section-three-layer-container {
    position: relative;
    width: 100%;
    height: 513px;
    top: 453px;
}
.mobile-section-three-layer0 {
    position: absolute;
    width: 100%;
    height: 513px;
    background: black;
    z-index: 1;
}
.mobile-section-three-layer1 {
    position: absolute;
    width: 100%;
    height: 513px;
}
.mobile-section-three-layer2 {
    position: absolute;
    width: 100%;
    height: 513px;
    opacity: 0;
}
.animation-showup0 {
  animation: showup 1s forwards;
}
.animation-showup {
    animation: showup 1s forwards 1s;
}
.animation-showup1 {
    animation: showup 1s forwards 0s;
}
.animation-showup2 {
    animation: showup 1s forwards 0.3s;
}
.animation-showup3 {
    animation: showup 1s forwards 0.3s;
}
.animation-showup4 {
    animation: showup 1s forwards 0.5s;
}
.animation-showup5 {
    animation: showup 1s forwards 0.7s;
}
.animation-showup6 {
    animation: showup 1s forwards 1.0s;
}
.animation-showup7 {
    animation: showup 1s forwards 1.3s;
}
.animation-showup8 {
    animation: showup 1s forwards 1.5s;
}

.mobile-section-three-layer3 {
    position: absolute;
    width: 100%;
    height: 513px;
    opacity: 0;
}

.ring {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 42px;
  height: 42px;
  z-index: 1;
  opacity: 0;
}

.mobile-position1 {top: 175px;left: 590px;}
.mobile-position2 {top: 240px;left: 415px;}
.mobile-position3 {top: 360px;left: 640px;}
.mobile-position4 {top: 350px;left: 550px;}
.mobile-position5 {top: 165px;left: 130px;}
.mobile-position6 {top: 243px;left: 155px;}
.mobile-position7 {top: 315px;left: 370px;}
.mobile-position8 {top: 385px;left: 185px;}

/* .circle, .circle1, .circle2, .circle3 {
    width: 15px;
    height: 15px;
    border: 1px solid #D6EEFF;
    border-radius: 999px;
    position: absolute;
}

.circle {
    background-color: #D6EEFF;
}

.circle1, .circle2, .circle3 {
    background: transparent;
    opacity: 0;
    animation: circleChange 4s linear infinite
}

.circle1 {
    animation-delay: 1s;
}

.circle2 {
    animation-delay: 2s;
}

.circle3 {
    animation-delay: 3s;
}

@keyframes circleChange {
    0% { transform: scale(1); opacity: 0.95;}
    25% { transform: scale(2); opacity: 0.75;}
    50% { transform: scale(3); opacity: 0.5;}
    75% { transform: scale(4); opacity: 0.25;}
    100% { transform: scale(5); opacity: 0.05;}
} */


/**/





.mobile-section-four-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #000000;
}


.mobile-section4litmatch {
    width: 100%;
}

.mobile-section-four-row-one {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-section-four-row-one-textbg1 {
    position: absolute;
    width: 100%;
    height: 430px;
    z-index: -1;
}

.mobile-section-four-row-one-textbg2 {
    position: absolute;
    width: 100%;
    height: 550px;
    top: 112px;
}

.mobile-section-four-text-fragment-one {
    width: 750px;
    height: 118px;
    font-size: 48px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 59px;
    margin-top: 118px;
    font-family: 'DIN-Bold';
    text-align: center;
}

.mobile-section-four-text-fragment-two {
    width: 600px;
    height: 216px;
    font-size: 26px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    margin-top: 20px;
    text-align: center;
}

.mobile-section-four-row-one-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    height: 580px;
    position: relative;
}

.mobile-section-four-row-two-text-container {
   width: 100%;
   height: 750px;
   display: flex;
   position: relative;
   flex-direction: column;
   align-items: center;
   position: relative;

}

.mobile-section-four-row-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 1350px;
}

.mobile-section-four-text-fragment-three {
    width: 497px;
    height: 118px;
    font-size: 48px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 59px;
    position: absolute;
    top: 328px;
    font-family: 'DIN-Bold';
    text-align: center;
}

.mobile-section-four-text-fragment-four {
    width: 572px;
    height: 180px;
    font-size: 26px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    position: absolute;
    top: 459px;
    text-align: center;
}

.mobile-section-five-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 800px;
    background-image: url('@/static/img/mobilehome/historybg.png');
    background-repeat: no-repeat;
    background-size: 750px 860px;
    padding-top: 60px;
    position: relative;
}

.mobile-section-five-title {
    width: 100%;
    height: 62px;
    font-size: 52px;
    font-family: 'DIN-Bold';
    color: #FFFFFF;
    line-height: 62px;
    text-align: center;
}

.mobile-history-container {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    margin-top: 100px;
}

.mobile-historyItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    height: 480px;
    background-image: url('@/static/img/mobilehome/Itembg.png');
    background-repeat: no-repeat;
    background-size: 360px 480px;
    margin-left: 10px;
    margin-right: 10px;
    flex-shrink: 0;
}

.mobile-historylogo {
    width: 56px;
    height: 56px;
}

.mobile-historyItemPart1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
}

.mobile-historyItemPart2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    width: 254px;
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 29px;
    text-align: center;
}

.mobile-historyItemPart3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 136px;
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
}

.mobile-section-six-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 1130px;
    background: #000000;
    color: #FFFFFF;
}

.mobile-section-six-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 160px;
    width: 100%;
    font-size: 52px;
    font-family: 'DIN-Bold';
    font-weight: bold;
    line-height: 62px;
}

.mobile-capability-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('@/static/img/mobilehome/capability.png');
    background-repeat: no-repeat;
    background-size: 750px 970px;
    width: 100%;
    height: 970px;
}

.mobile-capabilityItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    height: 320px;
}

.mobile-capabilitylogo {
    width: 126px;
    height: 126px;
}

.mobile-capabilityItemPart2 {
    width: 100%;
    height: 42px;
    font-size: 36px;
    font-weight: 600;
    line-height: 42px;
    text-align: center;
}

.mobile-capabilityItemPart3 {
    width: 374px;
    height: 72px;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    margin-top: 5px;
    text-align: center;
}

.mobile-section-seven-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 1100px;
    overflow: hidden;
    position: relative;
}

.mobile-section-seven-container-bg {
    width: 100%;
    height: 1226px;
    z-index: 0;
    position: absolute;
    animation: zoomIn 40s infinite linear;
}

.mobile-section-seven-title {
    width: 612px;
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 34px;
    position: absolute;
    text-align: center;
    top: 308px;
}

.mobile-signature {
    width: 556px;
    height: 34px;
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 34px;
    margin-top: 40px;
    position: absolute;
    text-align: center;
    top: 710px;
}

.mobile-founderlineicon {
    width: 3px;
    height: 157px;
    background: #FFFFFF;
    margin-top: 120px;
    position: absolute;
}