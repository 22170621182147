.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw - 15px);
    height: 120px;
    position: absolute;
    /* opacity: 100%; */
    background: transparent;
    z-index: 2;
  }
  
  .topLinksContainer {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    margin-right: 156px;
  }
  
  .topLink {
    margin-left: 65px
  }
  
  .logo {
    width: 220px;
    height: 56px;
    margin-left: 120px;
  }

  
.link-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 150px; */
  height: 22px;
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 22px;
}

.active-link {
  position: absolute;
  width: 17px;
  height: 1px; 
  bottom: 30px;
  border-bottom: 1.5px solid #FFFFFF;
}