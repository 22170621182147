.mobile-careers-section-one-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: black;
  background-image: url('@/static/img/mobilecareers/careersbg1.png');
  width: 100%;
  height: 1314px;
  background-size: 100% 1314px;
  background-repeat: no-repeat;
  z-index: 1;
}

.mobile-careers-section-one-title {
  height: 62px;
  width: 600px;
  font-size: 58px;
  font-family: 'DIN-Bold';
  color: #FFFFFF;
  line-height: 62px;
  text-align: center;
}

.mobile-careers-section-two-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #000000;
}

.mobile-careers-section-two-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #000000;
  height: 1355px;
}

.mobile-career-video {
  /* position: relative; */
  left: -400px;
  height: 644px;
  /* width: 100%; */
}

.mobile-careers-section-two-title {
  width: 598px;
  height: 59px;
  font-size: 48px;
  font-family: 'DIN-Bold';
  color: #FFFFFF;
  line-height: 59px;
  text-align: center;
}

.mobile-careers-section-two-subtitle {
  width: 558px;
  height: 228px;
  font-size: 26px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 38px;
  text-align: center;
  margin-top: 12px;
}

.mobile-allCareersLink-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 85px;
}

.mobile-allCareersLink {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 360px;
  height: 76px;
  background: #FFFFFF;
  margin-top: 74px;
}

.mobile-bagIcon {
  width: 26px;
  height: 27px;
  position: absolute;
  left: 30px;
}

.mobile-career-video-container {
  position: absolute;
  width: 100%;
  height: 630px;
  top: 534px;
  overflow: hidden;
}

.mobile-career-video-layer {
  position: absolute;
  width: 100%;
  height: 632px;
  z-index: 1;
  
}



.mobile-allCareersText {
  width: 100%;
  height: 31px;
  font-size: 26px;
  font-weight: bold;
  color: #03030B;
  text-align: center;
  line-height: 31px;
}

.mobile-careers-section-three-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #04040C;
  height: 1100px;
}

.mobile-careers-section-three-content {
  width: 100%;
}

.mobile-careers-section-three-content-video {
  width: 100%;
}

.mobile-careers-section-three-content-gif {
  width: 100%;
}

.mobile-section-three-footer {
  flex: 1;
  background: #04040C;
  width: 100%;
}

.mobile-careers-section-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
}


.mobile-careers-section-three-title {
  width: 624px;
  height: 59px;
  font-size: 48px;
  font-family: 'DIN-Bold';
  color: #FFFFFF;
  line-height: 59px;
  margin-top: 40px;
  text-align: left;
}

.mobile-careers-section-three-subtitle {
  width: 624px;
  height: 252px;
  font-size: 26px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 36px;
  margin-top: 18px;
  text-align: left;
}


.mobile-careers-section-four-container {
  width: 100%;
  height: 1120px;
  background: gray;
}

.mobile-careers-section-five-container {
  width: 100%;
  height: 880px;
  background: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-careers-section-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700;
  height: 343px;
  background: #000000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  top: -120px;
  padding: 25px 40px;
}

.mobile-careers-section-five-title {
  width: 624px;
  font-size: 48px;
  font-family: 'DIN-Bold';
  color: #FFFFFF;
  line-height: 59px;
}

.mobile-career-section-five-subtitle{
  width: 624px;
  height: 204px;
  font-size: 26px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 34px;
  margin-top: 10px;
  text-align: left;
}

.mobile-career-benefit {
  width: 100%;
  height: 413px;
}

.mobile-career-section-six-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 1100px;
  overflow: hidden;
  position: relative;
}

.mobile-career-section-six-container-bg {
  width: 100%;
  height: 1226px;
  z-index: 0;
  position: absolute;
  animation: zoomIn 40s infinite linear;
}

.mobile-section-six-title {
  width: 512px;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 34px;
  position: absolute;
  text-align: center;
  top: 308px;
}

.mobile-constructyourfutureLink {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 440px;
  height: 66px;
  background: #FFFFFF;
  margin-top: 500px;
}

.mobile-constructyourfutureText {
  width: 100%;
  height: 31px;
  font-size: 26px;
  font-weight: bold;
  color: #03030B;
  text-align: center;
  line-height: 31px;
}

.mobile-bottomLinksContainer {
  display: flex;
  margin-right: 156px;
}

.mobile-bottomLink {
  margin-right: 50px
}

.mobile-linkStyle {
  width: 109px;
  height: 22px;
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 22px;
  text-decoration: none;
}