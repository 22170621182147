.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 15px);
  height: 120px;
  position: fixed;
  background: transparent;
  z-index: 2;
}

.mobile-navigator-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.mobile-navigator-links-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 300px;
  height: 400px;
  width: 100%;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
}

.mobile-navigator-link {
    font-size: 30px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 36px;
    height: 133px;
    width: 100%;
}

.mobile-navigator-link-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

  .mobile-navigator-logo {
    position: absolute;
    width: 220px;
    height: 56px;
    left: 255px;
    top: 20px;
  }
  
  .mobile-topLinksContainer {
    display: flex;
    margin-right: 156px;
  }
  
  .mobile-topLink {
    margin-left: 65px
  }
  
  .mobile-logo {
    width: 220px;
    height: 56px;
    margin-left: 30px;
  }

  .mobile-navigator-icon {
    width: 34px;
    height: 31px;
    margin-right: 30px;
  }

 