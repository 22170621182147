.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 90px;
    background: #000000;
  }

  .constructLabel {
    /* width: 600px; */
    height: 22px;
    font-size: 16px;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 22px;
    margin-left: 100px;
  }

  .footer-active-link {
    position: absolute;
    width: 17px;
    height: 1px; 
    bottom: 20px;
    border-bottom: 1.5px solid #FFFFFF;
  }