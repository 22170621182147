.animation-enter-initial-properties {
    opacity: 0;
    transform: translateY(100px);
}

.animation-enter-up {
    animation: animation-enter-up 0.5s forwards;
}

@keyframes animation-enter-up {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes animation-enter-up-and-rotate {
    from {
        opacity: 0;
        transform: translateY(100px) rotate(-0.04turn)
    }
    to {
        opacity: 1;
        transform: translateY(0) rotateX(0)
    }
}

.animation-enter-up-and-rotate {
    animation: animation-enter-up-and-rotate 0.6s forwards
}