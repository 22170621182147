.mobile-what-we-build-section-one-container {
    display: flex;
    position: relative;
    background-image: url('@/static/img/mobilewwb/whatwebuildbg.png');
    height: 1775px; /*滚动条宽度 15*/
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 893px;
    z-index: 1;
}

.mobile-litmatch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 312px;
    width: 100%;
    text-align: center;
}

.mobile-wwblitmatchlogo {
    width: 87px;
    height: 87px;
}

.mobile-wwb-litmatch-title {
    height: 62px;
    font-size: 58px;
    font-family: "DIN-Bold";
    color: #FFFFFF;
    line-height: 62px;
    margin-top: 40px;
    text-align: center;
}
.mobile-wwb-litmatch-subtitle {
    width: 584px;
    height: 84px;
    font-size: 32px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 42px;
    margin-top: 20px;
    text-align: center;
}

.mobile-wwb-arrow-pointer {
    position: absolute;
    top: 900px;
    left: 360px;
    width: 32px;
    height: 19px;
    cursor: pointer;
    z-index: 1;
}

.mobile-play-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 72px;
    border: 3px solid #FFFFFF;
    cursor: pointer;
    left: 220px;
    position: absolute;
}
  

.mobile-gotoLitmatchText {
    width: 203px;
    height: 34px;
    font-size: 28px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 34px;
    text-align: center;
}

.mobile-app-container {
    top: 893px;
    position: absolute;
    width: 100%;
    height: 1000px;
    background: #000000;
}

.mobile-left-app {
    position: absolute;
    left: 30px;
    top: 140px;
}

.mobile-right-app {
    position: absolute;
    top: 30px;
    right: 0;
}

.mobile-app-img {
    width: 447px;
    height: 730px;
}

.mobile-left-app-img {
    width: 400px;
    height: 580px;
}

.mobile-right-app-img {
    width: 546px;
    height: 830px;
}

.mobile-what-we-build-section-two-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 1170px;
    background: #000000;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.mobile-what-we-build-section-two{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFFFFF;
    width: 100%;
    height: 1166px;
    position: relative;
}

.mobile-what-we-build-section-two-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-wwb-unilogo {
    margin-top: 200px;
    width: 87px;
    height: 87px;
    margin-bottom: 40px;
}

.mobile-wwb-universe {
    height: 62px;
    font-size: 58px;
    font-family: 'DIN-Bold';
    color: #FFFFFF;
    line-height: 62px;
    text-align: center;
}

.mobile-wwb-cttf {
    width: 544px;
    height: 42px;
    font-size: 32px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 42px;
    text-align: center;
    margin-top: 20px;
}

.mobile-wwb-section2-video-container {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.mobile-wwb-section2-video {
    width: 750px;
    /* height: 970px; */
}

/* .mobile-coming-soon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 54px;
    border: 3px solid #FFFFFF;
    margin-bottom: 40px;
} */

.mobile-coming-soon-text {
    height: 42px;
    font-size: 32px;
    font-weight: 400;
    color: #00FFE9;
    line-height: 42px;
    text-align: center;
    margin-top: 20px;
}