body {
  margin: 0;
  font-family: 'DIN' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
    width: 0;
    height: 0;
}

video {
  -webkit-filter: brightness(108.5%);
}


@font-face {
  font-family: 'DIN';
  src: url('@/static/font/DIN-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN-Bold';
  src: url('@/static/font/DIN-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  line-height: 1.15; 
  -webkit-text-size-adjust: 100%; 
}

.app {
  width: 100vw;
  background-color: #000000;
}