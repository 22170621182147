.careers-section-one-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url('@/static/img/careers/careersbg.png');
  width: 1920px;
  height: 1120px;
  max-height: 100vh;
  background-repeat: no-repeat;
  background-size: 1920px min(1120px, 100vh);
  z-index: 1;
}

.careers-section-one-title {
  /* width: 800px; */
  height: 72px;
  font-size: 72px;
  font-family: 'DIN-Bold';
  color: #FFFFFF;
  line-height: 72px;
  letter-spacing: 9px;
  text-shadow: 0px 6px 34px rgba(10, 10, 36, 0.25);
  opacity: 0;
  transform: translateY(100px);
} 



.animation-careers-title {
  animation: animation-careers-title 0.5s forwards
}

@keyframes animation-careers-title {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.careers-section-two-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 300px;
  width: 800px;
  height: 200px;
  opacity: 0;
  transform: translateY(100px);
}

.careers-section-two-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #000000;
  height: 1120px;
}

.allCareersVideo {
  width: 100%;
}

.careers-section-two-title {
  height: 71px;
  font-size: 58px;
  font-family: 'DIN-Bold';
  color: #FFFFFF;
  line-height: 71px;
}

.careers-section-two-subtitle {
  width: 800px;
  height: 102px;
  margin-top: 16px;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 34px;
  text-align: center;
}

.allCareersLink:hover {
  background: transparent;
  color: #FFFFFF;
  border: 3px solid #FFFFFF;
}

.allCareersLink:hover .allCareersText {
  background: transparent;
  color: #FFFFFF;
}

.allCareersLink:hover .bagIcon {
  background-image: url("@/static/img/careers/bagw.png");
}

.allCareersLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 66px;
  background: #FFFFFF;
  margin-top: 100px;
  border: 3px solid #FFFFFF;
  position: absolute;
  z-index: 2;
  left: 820px;
  opacity: 0;
  transform: translateY(100px);
}

.bagIcon {
  width: 28px;
  height: 27px;
  position: absolute;
  left: 30px;
  background-image: url("@/static/img/careers/bagb.png");
  background-size: cover;
}

.allCareersVideoLayerContainer {
  position: relative;
  width: 100%;
  margin-top: 60px;
  cursor: pointer;
}

.allCareersVideoLayer {
  position: absolute;
  width: 100%;
  z-index: 1;
}


.allCareersText {
  width: 160px;
  height: 22px;
  font-size: 18px;
  font-weight: bold;
  color: #03030B;
  line-height: 22px;
  text-align: center;
}

.careers-section-three-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #04040C;
  height: 1120px;
}

.careers-section-three-content {
  width: 100%;
}

.careers-section-three-content-video {
  width: 100%;
}

.section-three-footer {
  flex: 1;
  background: #04040C;
  width: 100%;
}

.careers-section-three-title {
  width: 500px;
  height: 71px;
  font-size: 58px;
  font-family: 'DIN-Bold';
  color: #FFFFFF;
  line-height: 71px;
}

.careers-section-three-subtitle {
  width: 655px;
  height: 204px;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 34px;
  margin-top: 20px;
}

.careers-section-text {
  position: absolute;
  bottom: 300px;
  left: 200px;
  z-index: 1;
}

.careers-section-four-container {
  width: 100%;
  height: 1120px;
  max-height: 100vh;
  position: relative;
}

.careers-section-four-snapshots {
  display: flex;
  position: absolute;
  width: 1200px;
  height: 130px;
  z-index: 1;
  left: 360px;
}

.careers-snapshot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 130px;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 29px;
  background: rgba(3, 3, 12, 0.2);
  text-align: center;
}

.careers-snapshot-text {
  width: 250px;
}

.careers-section-four-left-icon-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 60px;
  top: 490px;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  z-index: 2;
  cursor: pointer;
}

.careers-section-four-right-icon-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 60px;
  top: 490px;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  z-index: 2;
  cursor: pointer;
}

.careers-section-four-Icon {
  width: 13px;
  height: 22px;
}

.careers-section-four-snapshots-text {
  width: 164px;
  height: 29px;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 29px;
  background: rgba(3, 3, 12, 0.2);
}

.careers-culture-container {
  display: flex;
  width: 100%;
  height: 1120px;
  max-height: 100vh;
  position: relative;
}

.careers-culture {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
}

.careers-culture-left-corner {
  position: absolute;
  left: 200px;
  bottom: 200px;
  width: 570px;
  height: 168px;
  color: #FFFFFF;
}

.careers-culture-right-corner {
  position: absolute;
  right: 200px;
  bottom: 200px;
  width: 570px;
  height: 168px;
  color: #FFFFFF;
}

.carousel-title {
  font-size: 58px;
  font-family: 'DIN-Bold';
  line-height: 71px;
}

.carousel-description {
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  margin-top: 20px;
}

.careers-section-five-container {
  width: 100%;
  height: 1120px;
  background: #000000;
  display: flex;
  align-items: center;
}

.careers-section-text-container {
  display: flex;
  width: 760px;
  height: 520px;
  background: #000000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  left: 90px;
  opacity: 0;
  transform: translateY(100px);
}

.careers-section-five-title {
  width: 600px;
  height: 71px;
  font-size: 58px;
  font-family: 'DIN-Bold';
  color: #FFFFFF;
  line-height: 71px;
  text-align: right;
}

.career-section-five-subtitle{
  width: 635px;
  height: 204px;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 34px;
  text-align: right;
  margin-top: 20px;
}

.career-benefit {
  position: relative;
  width: 1200px;
  height: 920px;
}
.careers-layer0 {
  position: absolute;
  background-color: #000000;
  top: 0;
  width: 1200px;
  height: 920px;
}

.animation-left-fanIn {
  animation: leftfanIn 1s forwards
}

@keyframes leftfanIn {
  to {
      width: 0;
  }
}


.careers-section-six-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 1120px;
}

.careers-section-six-title {
  width: 785px;
  height: 144px;
  font-size: 40px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 48px;
  text-align: center;
  opacity: 0;
    transform: translateY(100px);
}

.constructyourfutureLink:hover {
  border: 3px solid #FFFFFF;
  background: transparent;
}

.constructyourfutureLink:hover .constructyourfutureText {
  color: #FFFFFF;
}

.constructyourfutureLink {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 320px;
  height: 66px;
  background: #FFFFFF;
  margin-top: 100px;
  opacity: 0;
    transform: translateY(100px);
}

.constructyourfutureText {
  width: 300px;
  height: 22px;
  font-size: 18px;
  font-weight: bold;
  color: #03030B;
  line-height: 22px;
  cursor: pointer;
  text-align: center;
}

.bottomLinksContainer {
  display: flex;
  margin-right: 156px;
}

.bottomLink {
  margin-right: 50px
}

