.what-we-build-section-one-container {
    display: flex;
    position: relative;
    background-image: url('@/static/img/wwb/whatwebuildbg.png');
    width: 1920px;
    height: 1120px;
    max-height: 100vh;
    background-repeat: no-repeat;
    background-size: 1920px min(1120px, 100vh);
    z-index: 1;
}

.left-app-container {
    position: relative;
    width: 900px;
    opacity: 0;
    transform: translateX(300px)
}

@keyframes animationleftapp {
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

.animation-left-app {
    animation: animationleftapp 0.5s forwards;
}


.left-app {
    position: absolute;
    left: 230px;
    top: 250px;
}

.right-app {
    position: absolute;
    top: 180px;
    right: 0;
}

.right-app-img {
    width: 447px;
    height: 730px;
}



.left-app-img {
    position: absolute;
    left: 150px;
    top: 100px;
    width: 750px;
    height: 830px;
}


.right-litmatch-container {
    box-sizing: border-box;
    margin-top: 400px;
    margin-left: 200px;
    width: 600px;
    opacity: 0;
}

.wwblitmatchlogo {
    width: 87px;
    height: 87px;
}

.wwb-litmatch-title {
    width: 223px;
    height: 72px;
    font-size: 54px;
    margin-top: 20px;
    color: #FFFFFF;
    line-height: 72px;
    font-weight: bold;
    font-family: 'DIN-Bold';
    text-shadow: 0px 6px 34px rgba(10, 10, 36, 0.25);
}
.wwb-litmatch-subtitle {
    width: 600px;
    height: 80px;
    font-size: 30px;
    margin-top: 10px;
    color: #FFFFFF;
    line-height: 40px;
    text-shadow: 0px 6px 34px rgba(10, 10, 36, 0.25);
}

.gotoLitmatch {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 54px;
    border: 3px solid #FFFFFF;
    margin-top: 40px;
    cursor: pointer;
}

.gotoLitmatch:hover {
    background-color: #FFFFFF;
}

.gotoLitmatch:hover .gotoLitmatchText {
    color: #000000;    
}

.gotoLitmatchText {
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 22px;
}

.what-we-build-section-two-container {
    display: flex;
    height: 1000px;
    padding-left: 200px;
    background: #000000;
}

.what-we-build-section-two-left-part {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #FFFFFF;
    width: 500px;
    height: 866px;
}

.what-we-build-section-two-left-part-container {
    z-index: 1;
    opacity: 0;
}

.wwb-unilogo {
    width: 87px;
    height: 87px;
    margin-bottom: 40px;
}

.wwb-universe {
    width: 223px;
    height: 72px;
    font-size: 54px;
    font-weight: bold;
    font-family: 'DIN-bold';
    color: #FFFFFF;
    line-height: 72px;
    text-shadow: 0px 6px 34px rgba(10, 10, 36, 0.25);
    margin-bottom: 10px;
}

.wwb-cttf {
    width: 480px;
    height: 40px;
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    text-shadow: 0px 6px 34px rgba(10, 10, 36, 0.25);
    margin-bottom: 40px;
}

.what-we-build-section-two-right-part-layer {
    position: absolute;
    width: 1212px;
    height: 820px;
    top: 0;
    left: 0;
    z-index: 1;
}


.what-we-build-section-two-left-part {
    height: 970px;
}

.what-we-build-section-two-right-part {
    box-sizing: border-box;
    width: 1312px;
    height: 820px;
    margin-top: 150px;
    position: relative;
}

.wwb-page-section2-video {
    width: 1212px;
    height: 820px;
}

.coming-soon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 54px;
    border: 3px solid #FFFFFF;
    margin-bottom: 40px;
}

.coming-soon-text {
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 22px;
}


.animation-wwe-title-showUp {
    animation: animation-wwe-title-showUp 0.6s forwards;
}

@keyframes animation-wwe-title-showUp {
    from {
        opacity: 0;
        transform: translateY(100px)
    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
}