.litatom-dialog-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    animation-name: dialog-animation;
    animation-duration: 0.3s;
}

@keyframes dialog-animation {
    from {opacity: 0%;}
    to {opacity: 100%;}
}

.litatom-dialog-header {
    width: 100%;
}

.litatom-dialog-title {
    width: 100%;
}

.litatom-dialog-footer {
    display: flex;
    font-size: 15px;
    width: 100%;
}

.litatom-dialog-body {
    display: flex;
    width: 100%;
    height: 300px;
}

.litatom-dialog-content {
    display: flex;
    justify-content: center;
    width: 100%; /*to do */
}

.litatom-dialog-confirm-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.litatom-dialog-cancel-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

